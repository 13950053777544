import ImageFallback from "@components/core/Image/ImageFallback";
import Unfollow from "@components/pages/my-beatport/shared/Unfollow/Unfollow";
import LabelLink from "@components/shared/Labels/LabelLink";
import { LABEL_PLACEHOLDER } from "@lib/constants";
import { MyBeatportContext } from "@lib/context/my-beatport";
import { useSessionContext } from "@lib/context/session";
import { deleteFollowRequest } from "@lib/network/my";
import { Label } from "@models/label";
import { MyBeatportLabel } from "@models/my-beatport";
import React, { useContext } from "react";
import {
	CropLink,
	ImageWrapper,
	LabelName,
	Meta,
	ReleaseCount,
	Wrapper,
} from "./LabelCard.style";

interface MyBeatportProps {
	label: MyBeatportLabel;
	type?: "my-beatport";
	location?: string;
}
interface LabelProps {
	label: Label;
	type?: "label";
	location?: string;
}

type UnionProps = MyBeatportProps | LabelProps;

type Props = UnionProps & {
	overrideImage?: string;
	className?: string;
};

const IMG_WIDTH = LABEL_PLACEHOLDER.image.width;
const IMG_HEIGHT = LABEL_PLACEHOLDER.image.height;
const FALLBACK_URL = LABEL_PLACEHOLDER.href;

const LabelCard: React.FC<Props> = ({ label, overrideImage = "", className = "", type = "label", location }) => {
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { setLabelsLoading } = useContext(MyBeatportContext);

	const unfollowLabel = async () => {
		await deleteFollowRequest({ id: label.id, type: "label", accessToken });
		setLabelsLoading(true);
	};
	return (
		<Wrapper className={className} data-testid="label-card">
			{type === "my-beatport" && <Unfollow handleUnfollow={unfollowLabel} />}
			<ImageWrapper>
				<LabelLink
					label={label}
					location={location}
					className="artwork"
				>
					<ImageFallback
						src={overrideImage ? overrideImage : label?.image?.uri || ""}
						alt={label?.name}
						width={IMG_WIDTH}
						height={IMG_HEIGHT}
						fallbackSrc={FALLBACK_URL}
						blurDataURL={FALLBACK_URL}
						placeholder="blur"
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</LabelLink>
			</ImageWrapper>
			<Meta>
				<LabelLink
					label={label}
					location={location}
				>
					<CropLink title={label.name} className="artwork">
						{"count" in label && label.count ?
								(
									<ReleaseCount data-testid="release-count">
										{label.count}&nbsp;
									</ReleaseCount>
								) :
								(
									<></>
								)}
						<LabelName>{label?.name}</LabelName>
					</CropLink>
				</LabelLink>
			</Meta>
		</Wrapper>
	);
};

export default LabelCard;
