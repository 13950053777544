import { UnfollowContainer } from "@components/pages/my-beatport/shared/Unfollow/Unfollow.style";
import styled from "styled-components";

export const CardLink = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 16px;
  overflow: hidden;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CropLink = styled.span`
  width: 100%;
  overflow: hidden;
`;

export const ReleaseCount = styled.span`
  color: ${(props) => props.theme.colors.green[300].accent};
`;

export const LabelName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  overflow: hidden;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  transition: top 0.2s ease-in-out;
  padding: 8px;
  gap: 4px;
`;

export const ImageWrapper = styled.div`
  display: block;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 262px;

  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  &:hover{
    ${UnfollowContainer} {
      opacity: 1;
    }
  }
`;
