import { cls } from "@lib/css";
import { Heading, Wrapper } from "./Panel.style";

export interface PanelProps {
	title?: React.ReactNode | string;
	actionsLeft?: React.ReactNode;
	actionsRight?: React.ReactNode;
	children?: React.ReactNode;
}

const Panel: React.FC<PanelProps> = ({
	title,
	actionsLeft,
	actionsRight,
	children,
}) => {
	const Title = () => {
		if (!title) return <></>;
		return (
			<div
				className={cls(
					"title",
					actionsLeft ? "actions-left" : undefined,
					actionsRight ? "actions-right" : undefined,
				)}
			>
				{title}
			</div>
		);
	};
	return (
		<Wrapper>
			<Heading>
				{actionsLeft}
				<Title />
				{actionsRight}
			</Heading>
			{children}
		</Wrapper>
	);
};

export default Panel;
