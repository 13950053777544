import Panel, { PanelProps } from "@components/core/Panel/Panel";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { cls } from "@lib/css";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Children, useCallback, useEffect, useRef, useState } from "react";
import {
	Actions,
	ButtonBackground,
	Dot,
	Dots,
	Item,
	Items,
	NextButton,
	PrevButton,
	Wrapper,
} from "./Carousel.style";

export interface Props extends PanelProps {
	children?: React.ReactNode;
	actionsRight?: React.ReactNode;
	negativeMargin?: boolean;
	loop?: boolean;
	speed?: number;
	showDots?: boolean;
	showNavArrows?: boolean;
	showRightNav?: boolean;
	viewAllPath?: string;
	onPageChange?: (index: number) => void;
}

const Carousel: React.FC<Props> = ({
	actionsRight,
	children,
	negativeMargin,
	loop,
	speed = 6000,
	showDots,
	showNavArrows,
	showRightNav,
	viewAllPath,
	onPageChange = () => {},
	...rest
}) => {
	const childrenArr = children ? Children.toArray(children) : [];
	const size = childrenArr.length;
	const [index, setIndex] = useState(0);
	const { t } = useTranslation();
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const handleGoToPrev = () => {
		setIndex(index > 0 ? index - 1 : size - 1);
	};

	const handleGoToNext = useCallback(() => {
		setIndex(index < size - 1 ? index + 1 : 0);
	}, [index, size]);

	useEffect(() => {
		onPageChange(index);
	}, [index, onPageChange]);

	useEffect(() => {
		if (loop) {
			timerRef.current = setTimeout(handleGoToNext, speed);
		}

		return () => {
			if (loop && timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [handleGoToNext, speed, loop]);

	const ViewAll = () => {
		return (
			<Actions rightPad={showRightNav}>
				<Link href={viewAllPath || ""} prefetch={false}>
					{t("Actions.ViewAll")}
				</Link>
			</Actions>
		);
	};

	return (
		<Panel
			{...rest}
			actionsRight={
				showRightNav ?
						(
							<>
								{viewAllPath && <ViewAll />}
								<Actions>
									<ButtonBackground
										onClick={() => {
											handleGoToPrev();
										}}
									>
										<SpriteIcon id="triangle-left" width="24" height="24" />
									</ButtonBackground>
									<ButtonBackground
										onClick={() => {
											handleGoToNext();
										}}
									>
										<SpriteIcon id="triangle-right" width="24" height="24" />
									</ButtonBackground>
								</Actions>

								{actionsRight}
							</>
						) :
						(
							<>
								{viewAllPath && <ViewAll />}
								{actionsRight}
							</>
						)
			}
		>
			<Wrapper role="marquee">
				<Items className={cls(negativeMargin ? "no-margin" : undefined)}>
					{showNavArrows && (
						<PrevButton
							onClick={() => {
								handleGoToPrev();
							}}
						>
							<SpriteIcon id="indicator-left" width="33" height="90" />
						</PrevButton>
					)}
					{childrenArr.map((item, idx) => {
						return (
							<Item
								key={`carousel-item-${idx}`}
								className={idx === index ? "active" : undefined}
							>
								{item}
							</Item>
						);
					})}
					{showNavArrows && (
						<NextButton
							onClick={() => {
								handleGoToNext();
							}}
						>
							<SpriteIcon id="indicator-right" width="33" height="90" />
						</NextButton>
					)}
				</Items>
				{showDots && (
					<Dots>
						{Array.from(Array(size).keys()).map((_, idx) => (
							<Dot
								key={`carousel-dot-item-${idx}`}
								className={index === idx ? "active" : undefined}
								onClick={() => {
									setIndex(idx);
								}}
							>
								<span></span>
							</Dot>
						))}
					</Dots>
				)}
			</Wrapper>
		</Panel>
	);
};

export default Carousel;
