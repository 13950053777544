import styled from "styled-components";

export const HeadingH1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-weight: 500;
  font-style: normal;
  font-size: ${(props) => props.theme.fontSizes[9].size};
  line-height: 36px;
  letter-spacing: 0.3px;

  span.gray {
    color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }
`;

export const HeadingH2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-weight: 500;
  font-style: normal;
  font-size: ${(props) => props.theme.fontSizes[8].size};
  line-height: 30px;
  letter-spacing: 0.3px;
`;

export const SmallH1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-weight: 500;
  font-style: normal;
  font-size: ${(props) => props.theme.fontSizes[8].size};
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 1.5rem;
`;

export const SubHeading = styled.h2`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: ${(props) => props.theme.fontSizes[6].lineHeight};
  letter-spacing: ${(props) => props.theme.fontSizes[6].letterSpacing};
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  max-width: ${(props) => props.theme.size.xsm};
  
  a {
    text-decoration: underline;
  }

  a:hover {
    color: ${(props) => props.theme.colors.neutrals.primary.white};  
  }
`;

export const EmptyState = styled.div`
  margin: auto;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
`;

export const MyBeatportEmptyState = styled.div`
  span.uppercase {
    text-transform: uppercase;
  }
  max-width: 468px;
  padding-top: 20px;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: "normal";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
`;
