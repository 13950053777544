import { UnfollowContainer } from "@components/pages/my-beatport/shared/Unfollow/Unfollow.style";
import styled from "styled-components";

export const Actions = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  padding: 8px;

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    justify-content: center;
    gap: 10px;
    padding: 0 0 0 4px;
    flex-wrap: nowrap;
    &.no-price {
      justify-content: center;
      gap: 16px;
    }
    &.price {
      &:last-child {
        span {
          border-radius: 0;
        }

        span.price {
          min-width: 50px;
          width: 50px;
        }

        span.arrow {
          padding: 4px 1px;
          width: 20px;
        }
      }
    }
  }
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 125px;
  top: 100%;
  transition: top 0.2s ease-in-out;
  overflow: hidden;

  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    background: #696969;
    visibility: hidden;
    opacity: 0;
    //transition: opacity 0.2s ease-in-out;
  }
`;

export const CardButton = styled.a`
  cursor: pointer;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  cursor: pointer;
`;

export const CardControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-top: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};
  z-index: 10;

  @media ${(props) => props.theme.device.lg} {
    display: none;
  }
`;

export const Title = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;

  position: absolute;
  bottom: 20px;
  padding: 16px;

  transition: bottom 0.2s ease-in-out;
  z-index: 2;
`;

export const Name = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;

  position: absolute;
  bottom: 0px;
  padding: 16px;

  transition: bottom 0.2s ease-in-out;
  z-index: 2;
`;

export const ReleaseCount = styled.span`
  color: ${(props) => props.theme.colors.green[300].accent};
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #000 0, transparent 50%);
  z-index: 1;
  bottom: 0px;
  transition: bottom 0.2s ease-in-out;
`;

export const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
`;

export const Meta = styled.div`
  width: 100%;
  position: relative;

  &:hover{
    ${UnfollowContainer} {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &.actions {
    ${CardActions} {
      top: calc(100% - 120px);
      z-index: 2;
    }
  }

  &.hide-artist {
    ${Title} {
      bottom: 0px;
    }
  }

  @media ${(props) => props.theme.device.lg} {
    ${CardActions} {
      top: 0;
      z-index: 2;
      top: 100%;
      visibility: visible;
      opacity: 1;
    }

    &.hide-artist:hover {
      ${Title} {
        bottom: 24px;
      }
    }
  }

  @media ${(props) => props.theme.device.sm} {
    max-width: 262px;
    &.full-width {
      max-width: 100%;
    }
  }
`;
