import styled from "styled-components";

export const UnfollowContainer = styled.div<{ isMobileDevice: boolean }>`
  cursor: pointer;
  z-index: 10;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;
  opacity: 0;
  transition: opacity ease-in 80ms;

  ${({ isMobileDevice }) => isMobileDevice && "opacity: 1;"};
`;

export const UnfollowButton = styled.button`
width: 40px;
height: 40px;
  > svg {
    margin: 0 auto;
    height: 14px;
    width: 14px;
  }
`;
