import styled from "styled-components";

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  padding: 4px 0;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div``;
