import styled from "styled-components";

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-stary;
  align-items: center;
  padding-bottom: 4px;

  div.title {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
`;
