import ImageFallback from "@components/core/Image/ImageFallback";
import Unfollow from "@components/pages/my-beatport/shared/Unfollow/Unfollow";
import { ARTIST_PLACEHOLDER } from "@lib/constants";
import { MyBeatportContext } from "@lib/context/my-beatport";
import { useSessionContext } from "@lib/context/session";
import {
	deleteFollowRequest,
} from "@lib/network/my";
import { Artist } from "@models/artist";
import { MyBeatportArtist } from "@models/my-beatport";
import Link from "next/link";
import { useContext } from "react";
import {
	ImageWrapper,
	Meta,
	Name,
	Overlay,
	ReleaseCount,
	Wrapper,
} from "./ArtistCard.style";

interface MyBeatportProps {
	artist: MyBeatportArtist;
	type?: "my-beatport";
}

interface ArtistsProps {
	artist: Artist;
	type?: "artist";
}

type UnionProps = MyBeatportProps | ArtistsProps;

type Props = UnionProps & {
	fullWidth?: boolean;
};

const IMG_WIDTH = ARTIST_PLACEHOLDER.image.width;
const IMG_HEIGHT = ARTIST_PLACEHOLDER.image.height;
const FALLBACK_URL = ARTIST_PLACEHOLDER.href;

const ArtistCard: React.FC<Props> = ({
	artist,
	fullWidth = false,
	type = "artist",
}) => {
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { setArtistsLoading } = useContext(MyBeatportContext);

	const unfollowArtist = async () => {
		await deleteFollowRequest({ id: artist.id, type: "artist", accessToken });
		setArtistsLoading(true);
	};

	return (
		<Wrapper
			className={`show-artist ${fullWidth ? "full-width" : ""}`}
			data-testid="artist-card"
		>
			<Meta>
				{type === "my-beatport" && (
					<Unfollow handleUnfollow={unfollowArtist} />
				)}
				<Link
					href={`/artist/${artist.slug}/${artist.id}`}
					prefetch={false}
					title={artist.name}
					className="artwork"
				>
					<Overlay />
					<Name>
						{"count" in artist && artist.count ?
								(
									<ReleaseCount data-testid="release-count">
										{artist.count}&nbsp;
									</ReleaseCount>
								) :
								(
									<></>
								)}
						{artist.name}
					</Name>
					<ImageWrapper>
						<ImageFallback
							src={artist.image?.uri}
							alt={artist.name}
							width={IMG_WIDTH}
							height={IMG_HEIGHT}
							fallbackSrc={FALLBACK_URL}
							blurDataURL={FALLBACK_URL}
							placeholder="blur"
							style={{
								width: "100%",
								height: "auto",
							}}
						/>
					</ImageWrapper>
				</Link>
			</Meta>
		</Wrapper>
	);
};

export default ArtistCard;
