import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { Tooltip } from "@components/interaction/Tooltip";
import { useMobile } from "@lib/hooks/useMobile";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { UnfollowButton, UnfollowContainer } from "./Unfollow.style";

interface Props {
	handleUnfollow: () => void;
}

const Unfollow: React.FC<Props> = ({ handleUnfollow }) => {
	const [disableUnfollow, setDisableUnfollow] = useState(false);
	const { isMobileDevice } = useMobile();
	const { t } = useTranslation("translation");

	const unfollow = () => {
		setDisableUnfollow(true);
		handleUnfollow();
	};

	return (
		<UnfollowContainer isMobileDevice={isMobileDevice}>
			<Tooltip text={t("Collection.Unfollow")} position="bottom">
				<UnfollowButton onClick={() => unfollow()} disabled={disableUnfollow}>
					<SpriteIcon id="unfollow" data-testid="unfollow-icon" />
				</UnfollowButton>
			</Tooltip>
		</UnfollowContainer>
	);
};

export default Unfollow;
